 
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function RotatingCardFront({ color, image, icon, title, description }) {
    return (
        <MKBox
            display="flex"
            justifyContent="center"
            alignContent="center"
            borderRadius="lg"
            coloredShadow={color}
            width="100%"
            position="relative"
            zIndex={2}
            sx={{
                //backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                //    `${linearGradient(
                //        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
                //        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
                //    )}, url(${image})`,
                //adjusted the 0.25 and 0.5 for gradient effect
                        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                    `${linearGradient(
                        rgba(gradients[color] ? gradients[color].main : gradients.dark.main, 0.25),
                        rgba(gradients[color] ? gradients[color].main : gradients.dark.main, 0.5)
                    )}, url(${image})`,

                backgroundSize: "cover",
                backfaceVisibility: "hidden",
            }}
        >
            <MKBox py={12} px={3} textAlign="center"   lineHeight={1}>
                {icon && (
                    <MKTypography variant="h2" color="#344767" my={2}>
                        {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
                    </MKTypography>
                )}
                <MKTypography variant="h3" color="#344767" gutterBottom>
                    {title}
                </MKTypography>
                <MKTypography variant="body2" color="#344767" opacity={0.8}>
                    {description}
                </MKTypography>
            </MKBox>
        </MKBox>
    );
}

// Setting default props for the RotatingCardFront
RotatingCardFront.defaultProps = {
    color: "info",
    icon: "",
};

// Typechecking props for the RotatingCardFront
RotatingCardFront.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    image: PropTypes.string.isRequired,
    icon: PropTypes.node,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
};

export default RotatingCardFront;
