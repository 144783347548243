 
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "../Examples/Cards/DefaultReviewCard";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";
import RotatingCard from "../Examples/Cards/RotatingCard";
import RotatingCardFront from "../Examples/Cards/RotatingCardFront";
import RotatingCardBack from "../Examples/Cards/RotatingCardBack";
import DefaultInfoCard from "../Examples/Cards/DefaultInfoCard";
function Information() {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                    <MKTypography variant="h2">Who we support</MKTypography>
                    {/*<MKTypography variant="h2" color="info" textGradient mb={2}>*/}
                    {/*    1,679,477+ web developers*/}
                    {/*</MKTypography>*/}
                    {/*<MKTypography variant="body1" color="text" mb={2}>*/}
                    {/*    Many Fortune 500 companies, startups, universities and governmental institutions love*/}
                    {/*    Creative Tim&apos;s products.*/}
                    {/*</MKTypography>*/}
                </Grid>
                {/*<Grid container spacing={3} sx={{ mt: 8 }}>*/}
                {/*    <Grid item xs={12} md={6} lg={4}>*/}
                {/*        <DefaultReviewCard*/}
                {/*            name="Patients"*/}
                {/*           // date="1 day ago"*/}
                {/*            review="Fetch, add and upload records. View all the health information through unified health records from any desktop or portable computing device any time, anywhere. Share, download and delete records."*/}
                {/*           // rating={5}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} md={6} lg={4}>*/}
                {/*        <DefaultReviewCard*/}
                {/*           // color="info"*/}
                {/*            name="Research"*/}
                {/*           // date="1 week ago"*/}
                {/*            review="Get access to population health data pool of deidentified health records."*/}
                {/*           // rating={5}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} md={6} lg={4}>*/}
                {/*        <DefaultReviewCard*/}
                {/*            name="Developers"*/}
                {/*            //date="3 weeks ago"*/}
                {/*            review="Health records from various sources received in structured and unstructired formats are converted to common target FHIR format and made accesible via API."*/}
                {/*           // rating={5}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                <Grid container spacing={4} sx={{ mt: 8 }}>
                    <Grid item xs={12}
                        md={6}
                        lg={4}
                    >
                        <RotatingCard>
                            <RotatingCardFront
                                //image={bgFront}
                                //icon="touch_app"
                                title={
                                    <>
                                        Patients
                                    </>
                                }
                                //description="Fetch, add and upload records. View all the health information through unified health records from any desktop or portable computing device any time, anywhere. Share, download and delete records."
                            />
                            <RotatingCardBack
                                //image={bgBack}
                             //   title="Patients"
                                description="View all the health information through unified health records from any desktop or portable computing device any time, anywhere. Share, download and delete records."
                              //  action={{
                            //        type: "internal",
                             //       route: "/sections/page-sections/page-headers",
                              //      label: "start with header",
                              //  }}
                            />
                        </RotatingCard>

                    </Grid>
                    <Grid item xs={12}
                        md={6}
                        lg={4}
                    >
                        <RotatingCard>
                            <RotatingCardFront
                                //image={bgFront}
                                //icon="touch_app"
                                title={
                                    <>
                                        Research
                                        {/*<br />*/}
                                        {/*Material Kit*/}
                                    </>
                                }
                              //  description="All the MUI components that you need in a development have been re-design with the new look."
                            />
                            <RotatingCardBack
                                //image={bgBack}
                              //  title="Research"
                                description="Get access to population health data pool of deidentified health records."
                            //  action={{
                            //        type: "internal",
                            //       route: "/sections/page-sections/page-headers",
                            //      label: "start with header",
                            //  }}
                            />
                        </RotatingCard>

                    </Grid>
                    <Grid item xs={12}
                        md={6}
                        lg={4}
                    >
                        <RotatingCard>
                            <RotatingCardFront
                                //image={bgFront}
                                //icon="touch_app"
                                title={
                                    <>
                                        Developers
                                        {/*<br />*/}
                                        {/*Material Kit*/}
                                    </>
                                }
                                //description="Health records from various sources received in structured and unstructired formats are converted to common target FHIR format and made accesible via API."
                            />
                            <RotatingCardBack
                                //image={bgBack}
                             //   title="Developers"
                                description="Health records from various sources received in structured and unstructired formats are converted to common target FHIR format and made accesible via API."
                            //  action={{
                            //        type: "internal",
                            //       route: "/sections/page-sections/page-headers",
                            //      label: "start with header",
                            //  }}
                            />
                        </RotatingCard>

                    </Grid>
                </Grid>

                {/*<Divider sx={{ my: 6 }} />*/}
                {/*<Grid container spacing={3} justifyContent="center">*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox component="img" src={appleLogo} alt="Apple" width="100%" opacity={0.6} />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox component="img" src={facebookLogo} alt="Facebook" width="100%" opacity={0.6} />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox component="img" src={nasaLogo} alt="Nasa" width="100%" opacity={0.6} />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox component="img" src={vodafoneLogo} alt="Vodafone" width="100%" opacity={0.6} />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox*/}
                {/*            component="img"*/}
                {/*            src={digitalOceanLogo}*/}
                {/*            alt="DigitalOcean"*/}
                {/*            width="100%"*/}
                {/*            opacity={0.6}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Container>
        </MKBox>
    );
}

export default Information;
