 
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

 
import MKBox from "components/MKBox";

import DefaultInfoCard from "../Examples/Cards/DefaultInfoCard";
import CenteredBlogCard from "../Examples/Cards/CenterBlogCard";
import BackgroundBlogCard from "../Examples/Cards/BackgroundBlogCard";
import unlockImg from '../Images/unlock.jpg';
import bg2 from '../Images/bg2.jpg';
import bg3 from '../Images/bg3.jpg';
 
import sharedr from '../Images/share-dr.jpg';
import access8 from '../Images/access8.jpg';

function PatientInformation() {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                   // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Got hospital discharge documents from a surgery that took place 8 years ago ?"
                                        description="Upload them within seconds to your account and see extracted health records get merged with your health profile only after careful verification and curation by an expert. You can always access the original document easily for reference and accuracy verification."
                                    />
                                </MKBox>
                            </Grid>
                           
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Want to know more about the allergic reaction episode which occurred 10 years ago after your PCP prescribed you a medication?"
                                        description="Bring in all the prescriptions , visit documents and lab reports. We will help you connect the dots."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Want to share imaging records for tests done in last few years with your doctor?"
                                        description="No need to carry that heavy bundle of CDs with you or spend  hours in phone calls just to make sure that your doctor will be able to access all the imaging results. Just upload the CD content to your profile and you're all set. Imaging study will be linked to appropriate visit and images can be viewed from within the platform."
                                    />
                                </MKBox>
                            </Grid>

                            {/*<Grid item xs={12}*/}
                            {/*//    md={6}//revert back if alignement does not work. will educe  the column width*/}
                            {/*>*/}
                            {/*    <MKBox*/}
                            {/*    // mb={5}*/}
                            {/*    >*/}
                            {/*        <DefaultInfoCard*/}
                            {/*            //icon="public"*/}
                            {/*            title="Got an upcoming appointment?"*/}
                            {/*            description="Unified health records can be shared with care giver or family member. You decide with whom and for how long."*/}
                            {/*        />*/}
                            {/*    </MKBox>*/}
                            {/*</Grid>*/}
                         
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                        {/*<CenteredBlogCard*/}
                        {/*   // image={unlockImg}*/}
                        {/*    image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"*/}
                        {/*    title="Unlock the true potential of health data "*/}
                        {/*    description="OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."*/}
                           
                        {/*/>*/}

                        <BackgroundBlogCard
                            //image={bg3}
                            image={access8}
                            title="Secure,compliant and always available"
                            description="Records will not be shared with anyone without patient's consent."

                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} alignItems="center">
                    
                    <Grid item xs={12} lg={6} >
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Too many EHR systems and credentials to remember?"
                                        description=" Just fetch the data from each system once and you're all set. Our platform will enable auto refresh or on demand data fetch based on your preferences."
                                    />
                                </MKBox>
                            </Grid>

                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Want to report daily observations or self treatable health conditions?"
                                        description="We've got you covered."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Did we just create a giant pool of disconnected pieces of information? "
                                        description="Yes. We need to carefully connect all these pieces to derive a meaningful usage out of them. Our proprietary data engine processes health records received from a variety of sources in a variety of formats and generates unified health records for you. So that next time you want to access or share any health information, it will be just a piece of cake!"
                                    />
                                </MKBox>
                            </Grid>

                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Want to contribute to science? "
                                        description="With patient's consent, their anonymized health records can be shared for advanced clinical research."
                                    />
                                </MKBox>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }} >
                        {/*<CenteredBlogCard*/}
                        {/*     image={bg2}*/}
                        {/*    //image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"*/}
                        {/*    //title="Unlock the true potential of health data "*/}
                        {/*    //description="OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."*/}

                        {/*/>*/}
                        <BackgroundBlogCard
                          //  image={cube}
                            image={sharedr}
                            title="You choose who can access the records."
                            description="Unified health records can be shared with care giver(s) or family member(s)."
                            
                        />
                    
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default PatientInformation;
