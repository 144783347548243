 
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PatientHorizontalTeamCard({ image, name, position, description }) {
    return (
        <Card sx={{ mt: 3 }} raised={true}>
            <Grid container>
                {/*<Grid item xs={12} md={6} lg={4} sx={{ mt: -6 }}>*/}
                {/*    <MKBox width="100%" pt={2} pb={1} px={2}>*/}
                {/*        <MKBox*/}
                {/*            component="img"*/}
                {/*            src={image}*/}
                {/*            alt={name}*/}
                {/*            width="100%"*/}
                {/*            borderRadius="md"*/}
                {/*            shadow="lg"*/}
                {/*        />*/}
                {/*    </MKBox>*/}
                {/*</Grid>*/}
                
                    <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
                        <MKTypography variant="h6">{name}</MKTypography>
                        {/*<MKTypography variant="h6" color={position.color} mb={1}>*/}
                        {/*    {description}*/}
                        {/*</MKTypography>*/}
                        <MKTypography variant="body2" color="text">
                            {description}
                        </MKTypography>
                    </MKBox>
                 
            </Grid>
        </Card>
    );
}

// Typechecking props for the HorizontalTeamCard
PatientHorizontalTeamCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
        label: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.string.isRequired,
};

export default PatientHorizontalTeamCard;
