import React from 'react';
import { useState } from 'react';
import Home from './Home';
import TBD from './TBD';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./assets/theme";

function App() {

    const Mesasurement_Id = "G-H2WJ7Z84KH"; // YOUR_OWN_TRACKING_ID
    ReactGA.initialize(Mesasurement_Id);


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        <ThemeProvider theme={theme}>
        <React.Fragment>
                <div>

                <Home>
                </Home>
               {/* <TBD></TBD>*/}
            </div>
            </React.Fragment>
        </ThemeProvider>
    );
}

export default App;
