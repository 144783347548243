 
import PropTypes from "prop-types";



// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function RotatingCard({ color, image, title, description, action }) {
    return (
        <MKBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="lg"
            coloredShadow={color}
            position="absolute"
            width="100%"
            height="100%"
            top={0}
            left={0}
            zIndex={5}
            sx={{
                //backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                //    `${linearGradient(
                //        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
                //        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
                //    )}, url(${image})`,

                //adjusted the 0.25 and 0.5 for gradient effect
                backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                    `${linearGradient(
                        //rgba(gradients[color] ? gradients[color].main : gradients.info.main, 3.05),
                        //rgba(gradients[color] ? gradients[color].main : gradients.error.main, 0.10)
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 5),
                        rgba(gradients[color] ? gradients[color].main : gradients.error.main, 0.10)
                    )}, url(${image})`,
                backgroundSize: "cover",
                backfaceVisibility: "hidden",
                transform: "rotateY(180deg)",
            }}
        >
            <MKBox
                //pt={12}
                pt={4}
                pb={2} px={2} textAlign="center" lineHeight={1}>
                <MKTypography variant="h3" color="#344767" gutterBottom>
                    {title}
                </MKTypography>
                <MKTypography variant="body2" color="#344767" opacity={0.8}>
                    {description}
                </MKTypography>
                {/*{action && (*/}
                {/*    <MKBox width="50%" mt={4} mb={2} mx="auto">*/}
                {/*        {action.type === "external" ? (*/}
                {/*            <MKButton*/}
                {/*                component={MuiLink}*/}
                {/*                href={action.route}*/}
                {/*                target="_blank"*/}
                {/*                rel="noreferrer"*/}
                {/*                color="white"*/}
                {/*                size="small"*/}
                {/*                fullWidth*/}
                {/*            >*/}
                {/*                {action.label}*/}
                {/*            </MKButton>*/}
                {/*        ) : (*/}
                {/*            <MKButton component={Link} to={action.route} color="white" size="small" fullWidth>*/}
                {/*                {action.label}*/}
                {/*            </MKButton>*/}
                {/*        )}*/}
                {/*    </MKBox>*/}
                {/*)}*/}
            </MKBox>
        </MKBox>
    );
}

// Setting default props for the RotatingCard
RotatingCard.defaultProps = {
    color: "info",
};

// Typechecking props for the RotatingCard
RotatingCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    image: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    action: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            type: PropTypes.oneOf(["external", "internal"]).isRequired,
            route: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ]).isRequired,
};

export default RotatingCard;
