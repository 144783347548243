 
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import PatientHorizontalTeamCard from "../Examples/Cards/PatientHorizontalTeamCard";

// Images
import step1 from '../Images/Steps/product-1-min.jpg';
import step2 from '../Images/Steps/product-2-min.jpg';
import step3 from '../Images/Steps/product-3-min.jpg';
import step4 from '../Images/Steps/product-4-min.jpg';
import step5 from '../Images/Steps/product-5-min.jpg';
import step6 from '../Images/Steps/product-6-min.jpg';
//import step4 from '../Images/Steps/product-4-min.jpg';

import cityprofile from '../Images/blog2.jpg';

function PatientTeam() {
    return (
        <MKBox
            component="section"
            variant="gradient"
            bgColor="dark"
            position="relative"
            py={6}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        >
            <Container>
                <Grid container>
                    <Grid item xs={12} md={8} sx={{ mb: 6 }}>
                        <MKTypography variant="h3" color="white">
                            How does it work?
                        </MKTypography>
                        {/*<MKTypography variant="body2" color="white" opacity={0.8}>*/}
                        {/*    Our platform developed with advanced technologies including proprietary data engine, cloud and AI,  enables you to bring in your health records from a variety of sources : EHR(s), paper documents, radiology cd/DVD and wearable devices.*/}
                        {/*</MKTypography>*/}
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {/*<Grid item xs={12}*/}
                    {/*    //lg={6}*/}
                    {/*>*/}
                    {/*    <MKBox mb={1}>*/}
                    {/*        <HorizontalTeamCard*/}
                    {/*            image={cityprofile}*/}
                    {/*            name="Emma Roberts"*/}
                    {/*            position={{ color: "info", label: "UI Designer" }}*/}
                    {/*            description="Our platform developed with advanced technologies including proprietary data engine, cloud and AI,  enables you to bring in your health records from a variety of sources : EHR(s), paper documents, radiology cd/DVD and wearable devices."*/}
                    {/*        />*/}
                    {/*    </MKBox>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} lg={4}>*/}
                    {/*    <MKBox mb={1}>*/}
                    {/*        <PatientHorizontalTeamCard*/}
                    {/*            image={step1}*/}
                    {/*            name="Step1"*/}
                    {/*           // position={{ color: "info", label: "Boss" }}*/}
                    {/*            description="Signup/SignIn"*/}
                    {/*        />*/}
                    {/*    </MKBox>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} lg={4}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <PatientHorizontalTeamCard
                                image={step2}
                                name="Step1"
                                position={{ color: "info", label: "Athlete" }}
                                description="Fetch health records from EHR system(s)."
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <PatientHorizontalTeamCard
                                image={step3}
                                name="Step2"
                                position={{ color: "info", label: "JS Developer" }}
                                description="Add Records : Radiology records and Paper records."
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <MKBox mb={1}>
                            <PatientHorizontalTeamCard
                                image={step4}
                                name="Step3"
                                description="Add Manual records :Vitals and conditions"
                            />
                        </MKBox>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                 
                    <Grid item xs={12} lg={4}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <PatientHorizontalTeamCard
                                image={step5}
                                name="Step4"
                                description="View Unified Health Records."
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <PatientHorizontalTeamCard
                                image={step6}
                                name="Step5"
                             
                                description="Share records."
                            />
                        </MKBox>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <MKBox mb={1}>
                            <PatientHorizontalTeamCard
                                image={step4}
                                name="Step6"
                                description="Download or delete health records."
                            />
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default PatientTeam;
